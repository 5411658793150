/**
 * Detect app running in production by checking `localhost, mm-dev3, st1-mm, numberly`
 * keywords **not** present in the URL.
 *
 * @returns {boolean}
 */
export const isProdEnv = function () {
  return !(document.location.hostname.search(/(localhost)|(mm-dev3)|(st1-mm)|(numberly)/) > -1)
}

/**
 * Detect app running in staging by detecting `numberly.st` keyword in the URL.
 *
 * @returns {boolean}
 */
export const isStagingEnv = function () {
  return (document.location.hostname.search(/(numberly.st)/) > -1)
}

// Detect debug mode on first call and keep data
const debugModeEnabled = (/debug=1/gi).test(window.location.search)

/**
 * Detect app running in debug mode.
 * To activate debug mode add param debug=1 to query string.
 *
 * @returns {boolean}
 */
export const isDebugMode = () => debugModeEnabled

/**
 * Remove multiples whitespaces and remove whitespace from both sides of a string.
 *
 * @returns {string}
 */
export const removeSpaces = function (string) {
  return string.replace(/\s\s+/g, ' ').trim();
}

/**
 * Remove multiples whitespaces and remove whitespace from both sides of a string.
 *
 * @returns {string}
 */
export const cleanNumber = function (string) {
  let cleanedString = string;
  cleanedString = cleanedString.replace(/\s/g, '');
  cleanedString = cleanedString.replace(/-/g, '');
  return cleanedString;
}

/**
 * Returns the selected decli configuration by searching in the URL parts of the
 * decli config `urls` array.
 *
 * @param {any[]} declis 
 * @returns {any}
 */
// vuepress@helpers-getdecli
export const getDecli = function (declis) {
  const selectedDecli = declis.filter(decli => {
    return decli.urls.filter(url => document.location.host.search(url) > -1).length > 0
  })[0]

  return selectedDecli || declis[0]
}
// vuepress@helpers-getdecli

/**
 * Check if in iframe context.
 *
 * @returns {boolean}
 */
export const inIframe = function () {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

/**
 * Map all sub-config objects of a decli to an object.
 *
 * @param {__WebpackModuleApi.RequireContext} context webpack require context
 * @returns {object} decli config
 */
export const decliEntryPoint = function (context) {
  return context.keys().reduce((toExport, key) => {
    // Remove `./` and file extensions
    const path = key
      .replace(/(^.\/)|(\.js$)|(\.ya?ml$)|(.json)/g, '')
      .split('/')
  
    if (path[0] === 'content') {
      toExport.content = Object.assign(toExport.content, context(key))
    } else if (path[0] !== 'index') {
      toExport[path[0]] = context(key).default
    }
  
    return toExport
  }, { content: {} })
}


export const getDeviceType = function () {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export const clearThree = function (obj){
  while(obj.children.length > 0){ 
    console.log('removing:', obj.children[0])
    clearThree(obj.children[0])
    obj.remove(obj.children[0]);
  }
  if(obj.geometry) {
    console.log('disposing geometry:', obj.geometry)
    obj.geometry.dispose()
  }

  if(obj.material){ 
    //in case of map, bumpMap, normalMap, envMap ...
    Object.keys(obj.material).forEach(prop => {
      if(!obj.material[prop])
        return         
      if(obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function') {
        console.log('disposing material in loop:', obj.material[prop])
        obj.material[prop].dispose()
      }
    })
    console.log('disposing material:', obj.material)
    if (obj.material != null && typeof obj.material.dispose === 'function') {
      obj.material.dispose()
    }
  }
}
