<template>
  <div>
    <div
      class="flex flex-row flex-wrap items-center justify-center m-auto lg:flex-row lg:w-8/12 container footer-container text-sm"
    >
      <!-- COOKIE MANAGEMENT -->
      <template v-if="$cookieScript">
        <div>
          <a
            class="cursor-pointer"
            data-cy="terms-open-cta"
            @click="$cookieScript.open()"
          >Cookie management</a>
          <span class="inline-block mx-2">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- COOKIE POLICY -->
      <template v-if="$cookieScript">
        <div>
          <a
            class="cursor-pointer"
            data-cy="terms-open-cta"
            href="https://www.louisxiii-cognac.com/en-us/cookies-policy.html"
            target="_blank"
          >Cookies policy</a>
          <span class="inline-block mx-2">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- TERMS -->
      <template v-if="$te('footer.terms')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="terms-open-cta"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })"
          >Rules*</a>
          <span class="inline-block mx-2">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- COOKIES -->
      <!-- <template v-if="$te('footer.cookiesPreferences')">
        <div> -->
          <!-- vuepress@iubenda-open-preferences-modal -->
          <!-- <button
            class="cursor-pointer iubenda-cs-preferences-link"
            @click="$iubenda.openPreferencesModal()"
          >
            {{$t('footer.cookiesPreferences')}}
          </button> -->
          <!-- vuepress@iubenda-open-preferences-modal -->
          <!-- <span class="dot">&nbsp;|&nbsp;</span>
        </div>
      </template> -->
      <!-- <template v-if="$te('footer.cookies')">
        <div> -->
          <!-- vuepress@iubenda-open-privacy-policy-modal -->
          <!-- <button
            class="cursor-pointer"
            data-cy="cookies-open-cta"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'IubendaPrivacyPolicyModal' })"
          >{{$t('footer.cookies')}}</button> -->
          <!-- vuepress@iubenda-open-privacy-policy-modal -->
          <!-- <span class="dot">&nbsp;|&nbsp;</span>
        </div>
      </template> -->
      <!-- REALISATION -->
      <template v-if="$te('footer.realisation.button')">
        <div>
          <a
            data-cy="realisation-cta" 
            class="cursor-pointer"
            @click="updateModalConfig({ isOpen: true, modalComponent: 'RealisationModal', modalClass: 'realisation-modal' })"
          >Achievement</a>
          <span class="inline-block mx-2">&nbsp;|&nbsp;</span>
        </div>
      </template>
      <!-- CONTACT -->
      <template v-if="$te('footer.contact.button')">
        <div>
          <a
            class="cursor-pointer mr-0"
            data-cy="contact-cta"
            :href="`mailto:${$t('footer.contact.email')}?subject=${$t('footer.contact.subject')}`"
            target="_blank"
          >{{$t('footer.contact.button')}}</a>
        </div>
      </template>
      <!-- CHARTE FB -->
      <!-- <template v-if="$te('footer.charteFacebook')">
        <div>
          <a
            class="cursor-pointer"
            data-cy="confidentialite-cta" 
            @click="updateModalConfig({ isOpen: true, modalComponent: 'CharteFbModal' })"
          >{{$t("footer.charteFacebook")}}</a>
          <span class="dot">&nbsp;|&nbsp;</span>
        </div>
      </template> -->
    </div>
    <!-- MENTIONS -->
    <div class="textFooter text-xs">
      <p v-html="$t('footer.mentions')"></p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'SiteFooter',
  methods: mapActions(['updateModalConfig'])
}
</script>

<style lang="scss" scoped>
a, button {
  outline: none;
}
.container {
  margin-top: 2.5rem;
  @media (max-width: 1024px) {
    margin-top: 2rem;
  }
}
.textFooter {
  max-width: 1280px;
}
.textFooter ::v-deep {
  margin: auto;
  text-align: center;
  margin: 15px auto 30px auto;
  padding: 0px 30px;
}
</style>
