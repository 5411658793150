<template>
  <ValidationObserver
    v-slot="{ handleSubmit }"
    ref="signUpObserver"
    tag="form"
    @submit.prevent
  >
    <header>
      <h3 class="text-xl text-primary">{{ $t("modals.shareEmail.title") }}</h3>
      <p class="pb-1 text-xs text-gray-dark">
        {{ $t("modals.shareEmail.subtitle") }}
      </p>
    </header>
    <div class="modal_body">
      <div v-for="(email, index) of lEmails" :key="index">
        <NText
          :data-cy="'share-email-input-' + (index + 1)"
          :id="'share-email-' + (index + 1)"
          :name="$t('register.form.email')"
          type="email"
          v-model="email.value"
          rules="email"
          labelPosition="side"
          errorTrigger="blur"
          :designType="panoplyStyle.inputs.designType"
          :borderColor="colorStyle.primary"
          :errorColor="colorStyle.error"
        >
          {{$t('modals.shareEmail.email', {index: index + 1})}}
        </NText>
        <span class="text-sm text-center text-error" v-show="email.hasError">{{
          $t("errors.email.alreadyUsed")
        }}</span>
      </div>

      <p class="pb-3">
        <small class="text-xs text-primary">
          {{ $t("modals.shareEmail.mentions") }}
        </small>
      </p>

      <p class="text-sm text-center text-error" v-show="errorMessage !== ''">
        {{ errorMessage }}
      </p>

      <div class="text-center text-primary" v-show="emailsSent">
        {{ emailsSent }}
      </div>
      <div class="text-center">
        <NCta  class="send-button mx-auto" data-cy="share-mail-submit" type="button" :borderRadius="'0px'" :backgroundColor="colorStyle.primary" :disabled="isLoading" @click.native="handleSubmit(submit)">
          <span v-show="!isLoading">{{$t('modals.shareEmail.submit')}}</span>
          <span class="flex" v-show="isLoading">
            <loading-icon
              class="m-auto mx-2 text-white"
              fill="white"
            ></loading-icon>
            <p class="mx-2">{{ $t("loading") }}</p>
          </span>
        </NCta>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { NText } from "@team-uep/n-inputs";
import NCta from "@team-uep/n-cta";
import LoadingIcon from '@/components/icons/LoadingIcon'
import design from '@/validators/design'

export default {
  name: "DiffusionEmailsModal",

  components: {
    NCta,
    NText,
    LoadingIcon,
  },

  data: () => ({
    colorStyle: design.colors,
    panoplyStyle: design.panoply,
    isLoading: false,
    lEmails: [
      { value: "", hasError: false },
      { value: "", hasError: false },
      { value: "", hasError: false },
    ],
    emailsSent: false,
    errorMessage: "",
  }),

  computed: {
    ...mapGetters({
      userEmail: "user/email",
    }),
  },

  methods: {
    submit: function () {
      const emails = this.emailObjToArray(this.lEmails);
      const message = this.handleErrorMessage(emails);

      if (message) {
        this.sendVirals(emails);
      }
    },

    handleErrorMessage: function (emails) {
      if (emails.length === 0) {
        this.errorMessage = this.$t("errors.email.noEmail");

        return false;
      }

      if (new Set(emails).size !== emails.length) {
        this.errorMessage = this.$t("errors.email.duplicateEmail");

        return false;
      }

      if (emails.includes(this.userEmail)) {
        this.errorMessage = this.$t("errors.email.ownEmail");

        return false;
      }

      return true;
    },

    emailObjToArray: function (lEmails) {
      return Object.keys(lEmails).reduce((final, emailKey) => {
        if (lEmails[emailKey].value) {
          final.push(lEmails[emailKey].value);
        }

        return final;
      }, []);
    },

    parseApiResult: function (lEmails, apiResult) {
      return Object.keys(apiResult).reduce(
        (final, email) => {
          const status = apiResult[email].code === 200 ? "good" : "bad";

          final[status].push(
            Object.keys(lEmails).find((key) => lEmails[key].value === email)
          );

          return final;
        },
        {
          good: [],
          bad: [],
        }
      );
    },

    sendVirals: function (emails) {
      const that = this;

      this.isLoading = true;

      this.$requestAPI
        .diffusionSendVirals({
          lEmails: emails,
        })
        .then(({ response, data }) => {
          const parsedResult = this.parseApiResult(this.lEmails, data.data[0]);

          this.$route.name === 'congratulations' ? this.$gtmTracking.shareActionPopin('Email') : this.$gtmTracking.shareActionSuccess('Email')

          // Handle bad emails
          parsedResult.bad.forEach((key) => {
            this.lEmails[key].hasError = true;
          });

          // Handle good emails
          parsedResult.good.forEach((key) => {
            this.lEmails[key].value = "";
            this.lEmails[key].hasError = false;
          });

          // If more than one good email is sent
          if (parsedResult.good.length > 0) {
            this.emailsSent =
              parsedResult.good.length > 1
                ? this.$tc("modals.shareEmail.success", 2)
                : this.$tc("modals.shareEmail.success", 1);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.send-button {
  margin: auto;
}
</style>
