import design from '@/validators/design'

export default {
  installed: false,

  // INSTALL THE PLUGIN
  install(Vue, { lang, siteId, cookiePolicyId, custom = {} }) {

    if (this.installed) return;

    this.installed = true;

    Vue.$iubenda = {

      _isInitialized: false,
      mmtroConsent: false,

      /**
       * Initialise cookie banner
       * 
       * @param {Object} config                 Cookie banner config
       * @param {string} config.lang            Lang of the current cookie banner
       * @param {number} config.siteId          Site ID of all cookie banner
       * @param {number} config.cookiePolicyId  Cookie policy ID of the current cookie banner
       * @param {Object} [config.custom={}]     Custom overrider config (from iubenda: csConfiguration)
       */
      async _initialize () {

        if (this._isInitialized) {
          return 
        }

        this._isInitialized = true
        
        const config = {

          lang,
          siteId,
          cookiePolicyId,
          enableRemoteConsent: true,
          consentOnContinuedBrowsing: false,
          perPurposeConsent: true,
          purposes: 'op-template',
          enableCMP: true,
          googleAdditionalConsentMode: true,
          askConsentIfCMPNotFound: false,
          floatingPreferencesButtonDisplay: false,

          banner:  {
            acceptButtonColor: design.colors.primary,
            rejectButtonColor: design.colors.primary,
            acceptButtonDisplay: true,
            customizeButtonDisplay: true,
            acceptButtonCaptionColor: 'white',
            customizeButtonColor: '#DADADA',
            customizeButtonCaptionColor: '#4D4D4D',
            rejectButtonDisplay: true,
            rejectButtonCaptionColor: 'white',
            position: 'float-center',
            textColor: 'black',
            backgroundColor: 'white',
            backgroundOverlay: true
          },
      
          callback: {
            onPreferenceExpressedOrNotNeeded: this.updateCookieConsent.bind(this),
          },
        }
      
        window._iub = window._iub || []
        window._iub.csConfiguration = Object.assign({}, config, custom)

        this._addHiddenBannerCTA()

        await this._appendScript({ type: 'text/javascript', src: '//cdn.iubenda.com/cs/tcf/stub-v2.js' })
        await this._appendScript({ type: 'text/javascript', src: '//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js' })
        await this._appendScript({ type: 'text/javascript', src: '//cdn.iubenda.com/cs/iubenda_cs.js', charset: 'UTF-8', async: true })
      },

      /**
       * Create hidden iubenda CTA to open consent modal later
       */
      _addHiddenBannerCTA () {
        // https://www.iubenda.com/en/help/1205-how-to-configure-your-cookie-solution-advanced-guide#classes
        this.cookieBannerCTA = document.createElement('button')
        this.cookieBannerCTA.classList.add('iubenda-cs-preferences-link')
        this.cookieBannerCTA.style.display = 'none'
        document.body.appendChild(this.cookieBannerCTA)
      },

      /**
       * Open iubenda consent modal
       * (by using the hidden CTA)
       */
      openPreferencesModal () {
        this.cookieBannerCTA.click()
      },

      /**
       * Can be an src of iframe
       * 
       * @returns {string} Privacy policy URL of page
       */
      getPrivacyPolicyURL () {
        return `https://www.iubenda.com/privacy-policy/${ cookiePolicyId }/cookie-policy`
      },

      /**
       * Can be an src of iframe
       * 
       * @returns {string} Cookie policy URL of page
       */
      getCookiePolicyURL () {
        return `https://www.iubenda.com/privacy-policy/${ cookiePolicyId }`
      },

      /**
       * Send cookies informations to tag manager
       * 
       * @param {Object} preference   Data from iubenda
       */
      updateCookieConsent (preference) {
                
        // Google Tag Manager
        // window.dataLayer = window.dataLayer || []
        // window.dataLayer.push({
        //   iubenda_ccpa_opted_out: window._iub.cs.api.isCcpaOptedOut(),
        // });

        if (!preference) {
                
          // Google Tag Manager
          // window.dataLayer.push({
          //   event: "iubenda_preference_not_needed",
          // })
        } else {
          if (preference.consent === true) {
                
            // Google Tag Manager
            // window.dataLayer.push({
            //   event: "iubenda_consent_given",
            // })
          } else if (preference.consent === false) {
                
            // Google Tag Manager
            // window.dataLayer.push({
            //   event: "iubenda_consent_rejected",
            // })
          } else if (preference.purposes) {
            for (var purposeId in preference.purposes) {
              if (preference.purposes[purposeId]) {

                // Check the mmTro consent
                if (purposeId === '5') {
                  this.mmtroConsent = true
                  Vue.$mmTro.sendRtg()
                }

                // Google Tag Manager
                // window.dataLayer.push({
                //   event: "iubenda_consent_given_purpose_" + purposeId,
                // })
              }
            }
          }
        }
      },

      /**
       * Inject script to DOM
       * 
       * @param {Object} attributes   Object attribute, ex: { type: 'text/javascript', src: '//cdn.iubenda.com/cs/tcf/stub-v2.js' }
       */
      _appendScript (attributes) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.onload = () => resolve(script)
          script.onerror = error => reject(error)
          Object.keys(attributes).forEach(name => {
            script.setAttribute(name, attributes[name])
          })
          document.head.appendChild(script)
          return script
        })
      },
    };

    Vue.$iubenda._initialize()

    Object.defineProperties(Vue.prototype, {
      $iubenda: {
        get() {
          return Vue.$iubenda;
        },
      },
    });
  },
};
