<template>
  <div class="optinisation-page">
    <!-- <img src="../assets/logo.png" /> -->

    <h1>{{ $t('optinisation.title') }}</h1>

    <p v-html="$t('optinisation.subtitle', { client: 'CLIENT_NAME' })"></p>
  </div>
</template>

<script>
export default {
  name: 'OptinisationPage',

  mounted() {
    this.$requestAPI
      .getSessionCurrent()
      .then((result) => {
        this.$mmTro.doubleoptin(this.$route.query);

        return result;
      })
      .catch(console.log);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.optinisation-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    max-width: 100px;
  }
  p {
    line-height: 23px;
  }
}
</style>
