//import './ie11' // IE11 support

import './styles/tailwind.scss'
import './styles/base/index.scss'

import Vue from 'vue'
import vSelect from 'vue-select'
import './sentry'
import Main from './Main.vue'
import store from './store'
import genRouter from './router'
import { sync } from 'vuex-router-sync'
const appConfig = require('@/declis')
import NCta from '@team-uep/n-cta'
import NCtaLoader from '@team-uep/n-cta-loader'
import NModal from '@team-uep/n-modal'
// jQuery for gtm tracking
import JQuery from 'jquery'
window.jQuery = JQuery

// RULES
import './rules'

// FORMS
import { getDecli, inIframe, isDebugMode, isProdEnv } from '@/helpers'// OP DATA

// OP DATA
import { version } from '../package.json'

// REMOVE CONSOLE LOG IN PRODUCTION
if (isProdEnv() && !isDebugMode()) {
  console.log = function () { }
}

// VUE CONFIG
Vue.config.productionTip = false
Vue.config.env = process.env.NODE_ENV // Env: to detect local vs build

// ACTIVATE PLUGINS
// import VueCookie from 'vue-cookie'
// Vue.use(VueCookie)



// GLOBALS
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import DynamicTemplateCompile from '@/components/DynamicTemplateCompile'
import SocialButton from '@/components/SocialButton'
Vue.component('SiteHeader', SiteHeader)
Vue.component('SiteFooter', SiteFooter)
Vue.component('Dynamic', DynamicTemplateCompile)
Vue.component('SocialButton', SocialButton)
Vue.component('NCta', NCta)
Vue.component('NCtaLoader', NCtaLoader)
Vue.component('NModal', NModal)
Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';

// GLOBAL MIXINS
import trackedClickMixin from '@/mixins/trackedClick';

Vue.mixin(trackedClickMixin);

// PLUGINS
import {
  fbSdkInit,
  twitterSdkInit,
  mmTro,
  iubenda,
  cookieScript,
  consentParent,  // If iframe -> Parent page lead cookie banner
  requestAPI,
  kwanko,
  gtm
} from './plugins'

import i18n from './i18n'


if (isDebugMode()) {
  console.log(`\n🐞 DEBUG MODE\n\n - Logs: enabled\n - MMtro: disabled\n - Sentry: disabled\n - Doorman: disabled\n\n`)
}

// Log current OP version
console.log('Client version', version)

const localeName = getDecli(appConfig.declis).name
const activeDecli = require(`@/declis/${localeName}/index.js`).default

// ROUTER
const syncRouter = genRouter(activeDecli.routes) // Create router
sync(store, syncRouter) // Sync router & store

// INIT API OP PLUGIN
appConfig.activeDecli = getDecli(appConfig.declis)
Vue.use(appConfig)
Vue.use(requestAPI);

Vue.$requestAPI.setSessionToken(store.getters['global/token'])

// CHECK WRAPPER
store.dispatch('checkIfWebView')

// INIT FB SDK PLUGIN WITH OPTION (for SDK language)
if (activeDecli.config.facebook.active) {
  Vue.use(fbSdkInit, {
    appIds: activeDecli.config.facebook.appIds,
    lang: activeDecli.config.facebook.fbSdkLang
  })
}

// INIT TWITTER SDK
if (activeDecli.config.twitter.active) {
  Vue.use(twitterSdkInit)
}

// MMTRO
Vue.use(mmTro)

// GTM Tracking
Vue.use(gtm)

// vuepress@consentParent-add
if (inIframe() && activeDecli.config.iframe.active) {

  // PARENT CONSENTMENT WITH IFRAME
  // Vue.use(consentParent, { origin: new URL(activeDecli.config.iframe.urlToRedirTo).origin })
}
// vuepress@consentParent-add
else {

  // COOKIE BANNER
  // Vue.use(iubenda, { lang: localeName, ...activeDecli.config.iubenda })
  
  
  // COOKIE BANNER
  Vue.use(cookieScript)
}

// AFFILIATE PIXEL
// Vue.use(kwanko, activeDecli.config.kwanko)

// TITLE META
document.title = activeDecli.config.siteTitle

// SAVE APPCONFIG IN A STORE
store.dispatch('config/updateAppConfig', appConfig)

// STORE DECLI CONTENT IN STORE
store.dispatch('config/updateActiveDecli', activeDecli)

/* eslint-disable no-new */
new Vue({
  store,
  router: syncRouter,
  i18n,
  render: h => h(Main)
}).$mount('#main')
