import store from '@/store';
export default {
  installed: false,

  // INSTALL THE PLUGIN
  install(_Vue) {
    if (this.installed) return;

    this.installed = true;

    _Vue.$gtmTracking = {
      initialized: false,
      init() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-M35X5WQ');
        this.initialized = true
      },
      pageView() {
        let userType = 'non inscrit'
        if (store.getters['user/user']) {
          if (store.getters['user/user'].oResponses && store.getters['user/user'].oResponses.iQuestion_4) {
            userType = 'connu'
          } else {
            userType = 'nouveau'
          }
        }
        this.sendEvent({
          'event': 'user',
          'user': {
           'userId': store.getters['user/idu'] || '',
           'userAccount': userType
           }
         });
      },
      openSharePopin() {
        this.sendEvent({
          'event': 'interaction',
          'category': 'share',
          'action': 'shareIcon',
          'label': 'view Platforms'
         })
      },
      shareActionPopin(platform) {
        this.sendEvent({
          'event': 'interaction',
          'category': 'share',
          'action': 'shareIcon',
          'label': `share on ${platform}`
         })
      },
      signupStep(formField) {
        this.sendEvent({
          'event': 'interaction',
          'category': 'signup',
          'action':'accountCreation',
          'label': formField
        })
      },
      signupCompleted() {
        this.sendEvent({
          'event': 'interaction',
          'category': 'signup',
          'action': 'accountCreation',
          'label': 'Completed'
        })
      },
      keyFound() {
        this.sendEvent({
          'event': 'interaction',
          'category': 'action',
          'action': 'codeFound',
          'label': 'view Platforms'
        })
      }, 
      shareActionSuccess(platform) {
        this.sendEvent({
          'event': 'interaction',
          'category': 'share',
          'action': 'shareMySuccess',
          'label': `share on ${platform}`
        })
      }, 
      discoverSuccess() {
        this.sendEvent({
          'event': 'interaction',
          'category': 'action',
          'action': 'codeFound',
          'label': 'Discover'
        })
      }, 
      logoRedirect() {
        this.sendEvent({
          'event': 'interaction',
          'category': 'navigation',
          'action': 'clicNavigation',
          'label': 'Logo'
        })
      }, 
      sendEvent(dataObj) {
        console.log('gtm obj sent:', dataObj)
        window.dataLayer.push(dataObj)
        if (!this.initialized) {
          this.init()
        }
      }
    };

    Object.defineProperties(_Vue.prototype, {
      $gtmTracking: {
        get() {
          return _Vue.$gtmTracking;
        },
      },
    });
  },
};
