<template>
  <div>
    <div class="chart" v-html="currentText"></div>
  </div>
</template>

<script>
export default {
  name: 'CharteFbModal',
  computed: {
    currentText() {
      return this.$t('footer.footer_chartefb_modal', { contact: this.$t('footer_contact_email'), subject: this.$t('footer_contact_subject'), client: this.$t('footer_chartefb_client') })
    }
  },
  mounted() {
    this.$emit('updateSize', 'max')
  },
}
</script>

<style lang="scss" scoped>
.chart ::v-deep {
  p {
    margin: 10px 0;
    font-size: 14px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    @apply text-primary;

    &:hover {
      @apply underline;
    }
  }

  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 22px;
  }
}
</style>