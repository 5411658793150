import Doorman from '@/router/doorman'
import store from '@/store';

export default [
  {
    name: 'loading',
    path: '',
    component: () => import('@/views/Loading'),
    meta: {
      pageId: 1,
      autoPageView: false,
      requiresAuth: false,
      afterEnd: false,
      nextPage: 'explanation'
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'society',
    path: 'mysteries',
    component: () => import('@/views/Society'),
    children: [
      {
        name: 'explanation',
        path: 'enter',
        component: () => import('@/views/Explanation'),
        meta: {
          pageId: 1,
          autoPageView: true,
          requiresAuth: false,
          afterEnd: false,
          nextPage: 'register'
        },
        beforeEnter (to, from, next) {
          Doorman(to, from, next)
        }
      },
      {
        name: 'register',
        path: 'form',
        component: () => import('@/views/Register'),
        meta: {
          pageId: 2,
          autoPageView: true,
          requiresAuth: false,
          afterEnd: false,
          nextPage: 'hub'
        },
        beforeEnter (to, from, next) {
          Doorman(to, from, next)
        }
      }
    ]
  },
  {
    name: 'hub',
    path: 'enigma',
    component: () => import('@/views/Hub'),
    meta: {
      pageId: 3,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
      conversionPage: true
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'information',
    path: 'information',
    component: () => import('@/views/Information'),
    meta: {
      autoPageView: false,
      requiresAuth: false,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'find',
    path: 'i-find-a-code',
    component: () => import('@/views/Find'),
    meta: {
      autoPageView: false,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'share',
    path: 'share',
    component: () => import('@/views/Share'),
    meta: {
      autoPageView: false,
      requiresAuth: false,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'congratulations',
    path: 'congratulations',
    component: () => import('@/views/Congratulations'),
    meta: {
      pageId: 17,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma1',
    path: 'enigma-1',
    component: () => import('@/views/enigma/Enigma1'),
    meta: {
      pageId: 4,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 1)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma2',
    path: 'enigma-2',
    component: () => import('@/views/enigma/Enigma2'),
    meta: {
      pageId: 5,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 2)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma3',
    path: 'enigma-3',
    component: () => import('@/views/enigma/Enigma3'),
    meta: {
      pageId: 6,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 3)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma4',
    path: 'enigma-4',
    component: () => import('@/views/enigma/Enigma4'),
    meta: {
      pageId: 7,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 4)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma5',
    path: 'enigma-5',
    component: () => import('@/views/enigma/Enigma5'),
    meta: {
      pageId: 8,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 5)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma6',
    path: 'enigma-6',
    component: () => import('@/views/enigma/Enigma6'),
    meta: {
      pageId: 9,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 6)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma7',
    path: 'enigma-7',
    component: () => import('@/views/enigma/Enigma7'),
    meta: {
      pageId: 10,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 7)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma8',
    path: 'enigma-8',
    component: () => import('@/views/enigma/Enigma8'),
    meta: {
      pageId: 11,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 8)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma9',
    path: 'enigma-9',
    component: () => import('@/views/enigma/Enigma9'),
    meta: {
      pageId: 12,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 9)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma10',
    path: 'enigma-10',
    component: () => import('@/views/enigma/Enigma10'),
    meta: {
      pageId: 13,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 10)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma11',
    path: 'enigma-11',
    component: () => import('@/views/enigma/Enigma11'),
    meta: {
      pageId: 14,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 11)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma12',
    path: 'enigma-12',
    component: () => import('@/views/enigma/Enigma12'),
    meta: {
      pageId: 15,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 12)
      Doorman(to, from, next)
    }
  },
  {
    name: 'enigma13',
    path: 'enigma-13',
    component: () => import('@/views/enigma/Enigma13'),
    meta: {
      pageId: 16,
      autoPageView: true,
      requiresAuth: true,
      afterEnd: false,
    },
    beforeEnter (to, from, next) {
      store.dispatch('user/setLastEnigmaId', 13)
      Doorman(to, from, next)
    }
  },
  {
    name: 'end',
    path: 'end',
    component: () => import('@/views/End'),
    meta: {
      pageId: 17,
      autoPageView: true,
      afterEnd: true,
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      Doorman(to, from, next)
    }
  }
]
