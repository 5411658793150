import Vue from 'vue';
import { isDebugMode } from '@/helpers'

import store from '@/store';

/**
 * Navigation guard called on the `beforeEnter` of each route of all routes in
 * a decli config.
 *
 * @param {import('vue-router').Route} to
 * @param {import('vue-router').Route} from
 * @param {(to?: import('vue-router').Route) => void} next
 * @returns {void}
 */
export default function(to, from, next) {
  // vuepress@doorman-end
  if (isDebugMode()) {
    // disable doorman for debug mode
    next()
  } else if (store.getters['global/opStatus'] === 1 && to.name === 'end') {
    // Hide end page if OP is not finished
    next({
      name: 'register',
      query: to.query,
    });
  } else if (store.getters['global/opStatus'] === 0 && !to.meta.afterEnd) {
    // Force end page if OP is finished
    next({
      name: 'end',
      query: to.query,
    });
  }
  // vuepress@doorman-end
  else {
    // vuepress@doorman-loggedin
    if (to.meta && to.meta.requiresAuth && !store.getters['user/idu'] && !store.getters['global/uid']) {
      next({
        name: 'register',
      });
    }
    // vuepress@doorman-loggedin
    else if (to.meta && to.meta.pageId !== null && to.meta.autoPageView) {
      // vuepress@doorman-pageview
      Vue.$requestAPI.pageView(to.meta.pageId)
        .then(({ response }) => {
          if (response.status === 200) {
            next();
          } else {
            next(false)
          }
        })
        .catch(error => {
          console.log(error)
          next(false)
        });
      // vuepress@doorman-pageview
    } else {
      next();
    }
  }
}
