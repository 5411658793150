export default {
  installed: false,

  isMmtroAccepted () {
    const url = new URL(window.location.href);
    switch (url.searchParams.get("tcp")) {
      case "1" :
        return true
      case "0" :
        return false
    }

    return null
  },

  // INSTALL THE PLUGIN
  async install(Vue, options) {

    if (this.installed) return;

    this.installed = true;

    Vue.$cookieScript = {

      _isInitialized: false,
      mmtroConsent: false,

      async _initialize () {

        if (this._isInitialized) {
          return 
        }

        this._isInitialized = true
        
        await this._appendScript({ type: 'text/javascript', src: '//cdn.cookielaw.org/scripttemplates/otSDKStub.js', charset: 'UTF-8', ["data-document-language"] : "true", ["data-domain-script"]: "42992b64-185e-4b73-8b76-6ab3a3d4f683", ["data-cookiescriptreport"] : "report" })
      },

      /**
       * Inject script to DOM
       */
      _appendScript (attributes) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.onload = () => resolve(script)
          script.onerror = error => reject(error)
          Object.keys(attributes).forEach(name => {
            script.setAttribute(name, attributes[name])
          })
          document.head.appendChild(script)
          return script
        })
      },

      open() {
        window.CookieScript.instance.show()
      }
    };

    const mmtroConsentByQueryParams = this.isMmtroAccepted()
    window.addEventListener('CookieScriptLoaded', () => {
      const CookieScript = window.CookieScript

      if (mmtroConsentByQueryParams === true) {
        CookieScript.instance.acceptAction(['categories'])
        CookieScript.instance.hide()
        Vue.$cookieScript.mmtroConsent = true
      } else if (mmtroConsentByQueryParams === false) {
        CookieScript.instance.hide()
        Vue.$cookieScript.mmtroConsent = false
      } else {
        Vue.$cookieScript.mmtroConsent = CookieScript.instance.currentState().categories.indexOf("targeting") > -1
      }
    });

    window.addEventListener('CookieScriptAcceptAll', function() {
      Vue.$cookieScript.mmtroConsent = true
      Vue.$mmTro.sendRtg()
    })

    window.addEventListener('CookieScriptAccept', (e) => {
      if (e.detail.categories.indexOf("targeting") > -1) {
        Vue.$cookieScript.mmtroConsent = true
        Vue.$mmTro.sendRtg()
      } else {
        Vue.$cookieScript.mmtroConsent = false
      }
    })

    await Vue.$cookieScript._initialize()

    // targeting
    Object.defineProperties(Vue.prototype, {
      $cookieScript: {
        get() {
          return Vue.$cookieScript;
        },
      },
    });
  },
};
