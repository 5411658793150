import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';
import Container from '@/views/Container';
import DesaboPage from '@/views/Desabo';
import ErrorPage from '@/views/Error';
import OptinisationPage from '@/views/Optinisation';
import AppInit from './appInit';

Vue.use(Router);

const genRouter = function(children) {
  const router = new Router({
    mode: window.history && window.history.pushState ? 'history' : null,
    routes: [
      {
        path: '/',
        component: Container,
        beforeEnter: AppInit,
        children,
      },
      {
        path: '/app/sub',
        name: 'DesaboPage',
        component: DesaboPage,
      },
      {
        path: '/error',
        name: 'ErrorPage',
        component: ErrorPage,
      },
      {
        path: '/optinisation',
        name: 'OptinisationPage',
        component: OptinisationPage,
        beforeEnter: AppInit,
      },
      {
        path: '*',
        redirect: '/',
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    // if (store.getters.isInWebView) {
    //   if (to.path !== '/') {
    //     next({ path: '/' })
    //     return
    //   }
    // }
    // const currentRoute = store.getters.decliConfig.routes.find(route => route.name === to.name)

    const activeDecliRoutes = store.getters['config/activeDecliRoutes'];
    const currentRoute = activeDecliRoutes.find((route) => route.name === to.name);

    if (currentRoute && currentRoute.meta && currentRoute.meta.requiresAuth) {
      // Vue.$apiOP
      //   .get('/session/current')
      //   .then((result) => {
      //     store.dispatch('updateUserSession', result.data.data[0])
      //     next()
      //   }).catch(() => {
      //     next({ name: 'register' })
      //   })

      Vue.$requestAPI.getSessionCurrent()
        .then(() => {
          next();
        })
        .catch(error => {
          // Redirections will be managed by events dispatched
          next(false)
          console.log(error);
        });
    } else {
      next();
    }
  });

  return router;
};

export default genRouter;
