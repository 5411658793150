<template>
  <div>
    <header>
      <i class="glyphicon glyphicon-user"></i>
      <br><br>
      <h3>Liste des gagnants</h3>
    </header>

    <!-- FRIENDS -->
    <div v-if="winners.length > 0">
      <ul class="list-friends list-unstyled">
        <li v-for="(winner, index) in winners" :key="index" class="friends-list-li row">
          <p class="col-sm-8 text-left">
            <i class="glyphicon glyphicon-user"></i> {{winner.Email}}
          </p>
          <p class="col-sm-4 status text-right" v-if="winner.subscribed">
            <span><b>Inscrit</b></span>
          </p>
          <p class="col-sm-4 status text-right" v-if="!winner.subscribed">
            <a :href="relanceEmail(winner)"> Relancer</a>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'WinnersModal',
  data () {
    return {
      winners: []
    }
  },
  computed: {
    ...mapGetters(['decliConfig']),
    relanceEmail () {
      return 'test@numberly.com'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
