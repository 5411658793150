import Vue from 'vue'
import i18n from './i18n'

import { getDecli } from '@/helpers'
import appConfig from '@/declis'

// VeeValidate and n-inputs
import { email, required, min, length } from 'vee-validate/dist/rules'
import { ValidationObserver, localize, extend } from 'vee-validate'
import { rules as nInputsRules } from '@team-uep/n-inputs'

/**
 * Override rule message with an i18n translation key
 * 
 * @param {Object} rule 
 * @param {String} translateKey 
 */
const editRuleMsg = (rule, translateKey) => {
  return Object.assign(
    {},
    rule,
    {
      message: (value, params) => {

        // Convert params names to value, value2, value3...
        const values = { value }
        const paramsNames = (rule.params || []).map(data => data.name || data)
        paramsNames.forEach((name, i) => values['value' + (i + 2)] = params[name])

        return i18n.t(translateKey, values)
      }
    }
  )
}

/**
 * Overided rule list
 */
const rules = {
  length,
  min,
  minDecant: editRuleMsg(min, 'errors.invalidDecantNumber'),
  email: editRuleMsg(email, 'errors.email.invalid'),
  required: editRuleMsg(required, 'errors.required'),
  requiredCheckbox: editRuleMsg(nInputsRules.checkboxRequired, 'errors.required'),
  ageMin: editRuleMsg(nInputsRules.ageMin, 'errors.birthday.tooYoung'),
  ageMax: editRuleMsg(nInputsRules.ageMax, 'errors.birthday.tooOld'),
  birthday: editRuleMsg(nInputsRules.date, 'errors.birthday.invalid'),
  nameLatin: editRuleMsg(nInputsRules.nameLatin, 'errors.invalid'),
  radioRequired: editRuleMsg(nInputsRules.radioRequired, 'errors.required'),
  alreadyRegistered: editRuleMsg(
    { validate: (_, [state]) => state !== 'true' },
    'errors.email.alreadyUsed'
  ),
  decantNumber: editRuleMsg(
    { validate: (_, [state]) => state !== 'true' },
    'errors.invalidDecantNumber'
  ),
  phoneMobileFr: editRuleMsg(
    { validate: (value) => value.match(/^0[6-7]( \d{2}){4}$/) },
    'errors.invalid'
  ),
  alphaNum: editRuleMsg(
    { validate: (value) => value.match(/^[a-zA-Z0-9_.-\s]*$/) },
    'errors.invalid'
  )
}

// Enable all rules tu vee-validate
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.component('ValidationObserver', ValidationObserver)

const localeName = getDecli(appConfig.declis).name

// If import fail : use import with static path (Cypress doesn't support dynamic import with variables)
import(`vee-validate/dist/locale/${localeName}.json`)
  .catch((val, res)=> import(`vee-validate/dist/locale/fr.json`))
  .then(module => {
    localize( localeName,
      module.default
    )
  }) 
  // Error will be send to the context