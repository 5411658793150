<template>
  <div> 
    <!-- vuepress@iubenda-get-privacy-policy-url -->
    <iframe class="cookieModal" :src="$iubenda.getPrivacyPolicyURL()" scrolling="yes" frameborder="0" allowtransparency="true"></iframe>
    <!-- vuepress@iubenda-get-privacy-policy-url -->
  </div>
</template>

<script>
export default {
  name: 'IubendaPrivacyPolicyModal',
  mounted() {
    this.$emit('updateSize', 'max')
  }
}
</script>

<style lang="scss" scoped>
.cookieModal {
  width: calc(100% + 50px);
  height: 90vh;
  margin: -25px 0 0 -25px;
}
</style>
