import store from '@/store';
import { isDebugMode, isProdEnv } from '@/helpers';
import sha256 from 'hash.js/lib/hash/sha/256';
import md5 from 'md5';

export default {
  installed: false,

  // INSTALL THE PLUGIN
  install(_Vue) {
    if (this.installed) return;

    this.installed = true;

    _Vue.$mmTro = {
      queue: {},

      doubleoptin(urlParam) { 
        this.addToQueue({ rtgpg: 'completeddoubleoptin' }, urlParam)
        this.$mmTro.sendRtg();
      }, 

      conversion(urlParam) {
        this.addToQueue({ rtgpg: 'completedform' }, urlParam)
      },

      addGenericPage(urlParam) {
        this.addToQueue({ rtgpg: 'generic' }, urlParam)
      },

      addFormPage(urlParam) {
        this.addToQueue({ rtgpg: 'form' }, urlParam)
      },

      addPageId(pageId, urlParam) {
        this.addToQueue({ rtgstep: pageId }, urlParam);
      },

      addToQueue(obj, urlParam) {
        Object.assign(this.queue, obj);

        this.queue._tagid = store.getters['global/oTagInfoTagid'];

        this.queue.rtgidfrom =
          store.getters['global/fromId'] ||
          (urlParam && urlParam.idfrom) ||
          store.getters['config/activeDecliConfig'].idfroms.untagged ||
          0;

        this.queue.rtgdevenv = isProdEnv() ? 0 : 1;

        if (store.getters['user/email'] || (urlParam && urlParam.e)) {
          let userEmail = (store.getters['user/email'] || urlParam.e).toLowerCase()
          this.queue.sha256 = sha256().update(userEmail).digest('hex')
          this.queue.m5e = md5(userEmail)
        }

        if (store.getters['user/idu']) {
          this.queue.rtgidu = store.getters['user/idu'];
          if (
            store.getters['user/user'] &&
            store.getters['user/user'].oResponses &&
            store.getters['user/user'].oResponses.iQuestion_4
          ) {
            this.queue.rtgnewregistered = 0;
          } else {
            this.queue.rtgnewregistered = 1;
          }
        }

        if (store.getters['global/visitId']) {
          this.queue.rtgvisitid = store.getters['global/visitId'];
        }

        // OPTINS
        if (store.getters['user/optins'] && store.getters['user/optins'].iOptin_2 != null && store.getters['user/optins'].iOptin_2 !== 0) {
          this.queue.rtgtypeconsentement2 = store.getters['user/optins'].iOptin_2;
        }

        if (store.getters['user/optins'] && store.getters['user/optins'].iOptin_3 != null) {
          this.queue.rtgtypeconsentement3 = store.getters['user/optins'].iOptin_3;
        }

        if (store.getters['user/optins'] && store.getters['user/optins'].iOptin_4 != null && store.getters['user/optins'].iOptin_4 !== 0) {
          this.queue.rtgtypeconsentement4 = store.getters['user/optins'].iOptin_4;
        }

        return this;
      },

      sendRtg() {
        // MMtro is disabled if the cookie banner was declined and debug mode is disabled
        const cookieConsent = (_Vue.$cookieScript && _Vue.$cookieScript.mmtroConsent)

        if (!isDebugMode() && cookieConsent) {
          if (window._troq) {
            const final = [];
  
            Object.keys(this.queue).forEach((key) => {
              final.push([key, this.queue[key]]);
            });
  
            window._troq.push.apply(window._troq, final);
          } else {
            window._troq = window._troq || [];
            window._troq.push(['tagid', store.getters['global/oTagInfoTagid']]);
  
            Object.keys(this.queue).forEach((key) => {
              window._troq.push([key, this.queue[key]]);
            });
  
            const ts = document.createElement('script');
            ts.type = 'text/javascript';
            ts.async = true;
            ts.src = '//mmtro.com/tro.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(ts, s);
          }
          this.queue = {}
        }
      },
    };

    Object.defineProperties(_Vue.prototype, {
      $mmTro: {
        get() {
          return _Vue.$mmTro;
        },
      },
    });
  },
};
