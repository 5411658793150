export default {
  namespaced: true,

  state: {
    Email: null,
    Etape: null,
    JeuOK: null,
    Nom: null,
    Prenom: null,
    idu: null,
    id_Civilite: null,
    id_Declinaison_Culture: null,
    id_From: null,
    iduParrain: null,
    idu_email: null,
    oDedup: null,
    oOptins: null,
    oResponses: null,
    telmobile: null,
    uid: null,
    isRelog: null,
    autoRegister: null,
    stateParams: null,
    lastEnigmaId: null
  },

  actions: {
    updateUser(context, payload) {
      // Save uid in localstorage
      if (!window.localStorage.getItem("louisxiisession") || window.localStorage.getItem("louisxiisession") != payload.uid) {
        window.localStorage.setItem('louisxiisession', payload.uid)
      }
      context.commit('UPDATE_USER', payload);
    },

    updateStateParams(context, payload) {
      context.commit('UPDATE_STATE_PARAMS', payload);
    },

    setRegisterData(context, payload) {
      context.commit('SET_REGISTER_DATA', payload);
    },

    setAutoRegister(context, payload) {
      context.commit('SET_AUTO_REGISTER', payload);
    },

    setRelog(context, payload) {
      context.commit('SET_RELOG', payload);
    },

    setLastEnigmaId(context, payload) {
      context.commit('SET_LAST_ENIGMA_ID', payload);
    }
  },

  mutations: {
    UPDATE_USER(state, payload) {
      state.Email = payload.Email || payload.sEmail;
      state.Etape = payload.Etape;
      state.JeuOK = payload.JeuOK;
      state.Nom = payload.Nom;
      state.Prenom = payload.Prenom;
      state.idu = payload.idu || payload.idU;
      state.id_Civilite = payload.id_Civilite;
      state.id_Declinaison_Culture = payload.id_Declinaison_Culture;
      state.id_From = payload.id_From;
      state.iduParrain = payload.iduParrain;
      state.idu_email = payload.idu_email;
      state.oDedup = payload.oDedup;
      state.oOptins = payload.oOptins;
      state.oResponses = payload.oResponses;
      state.telmobile = payload.telmobile;
      state.uid = payload.uid;
    },

    UPDATE_STATE_PARAMS(state, payload) {
      state.stateParams = payload;
    },

    SET_REGISTER_DATA(state, payload) {
      state.idu = payload.idu || payload.idU;
      state.uid = payload.uid;
    },

    SET_AUTO_REGISTER(state, payload) {
      state.autoRegister = payload;
    },

    SET_RELOG(state, payload) {
      state.isRelog = payload;
    },

    SET_LAST_ENIGMA_ID(state, payload) {
      state.lastEnigmaId = payload;
    }
  },

  getters: {
    user: state => state,

    email: state => state.Email,
    step: state => state.Etape,
    gameOK: state => state.JeuOK,
    lastname: state => state.Nom,
    firstname: state => state.Prenom,
    idu: state => state.idu,
    idCivility: state => state.id_Civilite,
    idDeclinaison: state => state.id_Declinaison_Culture,
    idFrom: state => state.id_From,
    iduParrain: state => state.iduParrain,
    iduEmail: state => state.iduEmail,
    dedup: state => state.dedup,
    optins: state => state.oOptins,
    responses: state => state.oResponses,
    phonenumber: state => state.telmobile,
    uid: state => state.uid,
    isRelog: state => state.isRelog,
    isAutoRegistered: state => state.autoRegister,
    stateParams: state => state.stateParams,
    lastEnigmaId: state => state.lastEnigmaId,

    fromId: (state, getters, rootState, rootGetters) => {
      // if (!isNaN(this.$route.query.idfrom)) {
      if (!isNaN(rootState.route.query.idfrom)) {
        return parseInt(rootState.route.query.idfrom);
      }

      // if (this.$store.getters['user/stateParams'] && this.$store.getters['user/stateParams'].idfrom) {
      if (rootGetters['user/stateParams'] && rootGetters['user/stateParams'].idfrom) {
        // return parseInt(this.$store.getters['user/stateParams']);
        return parseInt(rootGetters['user/stateParams']);
      }

      // if (
      //   this.$store.getters['config/activeDecliConfig'].idfroms &&
      //   this.$store.getters['config/activeDecliConfig'].idfroms.untagged
      if (
        rootGetters['config/activeDecliConfig'].idfroms &&
        rootGetters['config/activeDecliConfig'].idfroms.untagged
      ) {
        // return parseInt(this.$store.getters['config/activeDecliConfig'].idfroms.untagged);
        return parseInt(rootGetters['config/activeDecliConfig'].idfroms.untagged);
      }

      return 0;
    },
  },
};
