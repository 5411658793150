<template>
  <div class="desabo-page flex flex-col items-center min-h-full px-4">
    <div>
        <img
          width="150"
          height="100"
          class="py-6 block"
          src="../assets/numberly__logo__desktop.png"
          alt
          srcset
        />
    </div>
    <!-- DESABO -->
    <div v-if="$route.query.r == 0" class="text-center h-full flex flex-center flex-col md:m-auto justify-center">
      <h3 class="m-auto text-lg md:m-0 md:text-xl font-bold" v-html="$t('desabo.title')"></h3>
      <div class="flex flex-col items-center mb-10 md:m-0">
        <p class="text-md mb-6 md:mb-10 md:text-lg md:mt-2" v-html="$t('desabo.link', { reaboUrl })"></p>
        <NCta
              borderRadius="30px"
              data-cy="cta-resubscribe"
              @click.native="exitUrl(reaboUrl)"
              designType="round"
            >{{$t('desabo.button')}}</NCta>
      </div>
    </div>

    <!-- REABO -->
    <div v-if="$route.query.r == 1" class="text-center h-full flex flex-center flex-col items-center md:m-auto">
      <h3 class="text-lg m-auto md:text-xl  font-bold" v-html="$t('desabo.resub')"></h3>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DesaboPage',
  methods: {
    exitUrl() {
      window.open(this.reaboUrl, '_blank');
    }
  },
  computed: {
    ...mapGetters({
      appConfig: 'config/appConfig',
    }),
    reaboUrl() {
      return [
        `${document.location.protocol}//${document.location.host}`,
        `/api/v0/`,
        `${this.appConfig.baseName}/`,
        this.appConfig.activeDecli.apiVersion,
        `/static/inscription_subscribe`,
        `?idm=${this.$route.query.idm}`,
        `&uid=${this.$route.query.uid}`,
        '&r=1',
      ].join('');
    },
  },
};
</script>

<style lang="scss" scoped>
.n-cta ::v-deep span{
  text-transform: uppercase;
}
.desabo-page {
  width: 100vw;
  height: 100vh;
}
</style>
