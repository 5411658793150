import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from './../translations.base.csv'
import { getDecli } from '@/helpers'
import appConfig from '@/declis'
Vue.use(VueI18n)

const localeName = getDecli(appConfig.declis).name

const i18n = new VueI18n({
  locale: localeName || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: translations
})

export default i18n
