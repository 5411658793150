import store from '@/store';

/**
 * @param {import("vue-router").Route} to
 * @param {import("vue-router").Route} from
 * @param {(any) => void} next
 */
export default function(to, from, next) {
  const query = Object.entries(to.query);

  let urlRedir = store.getters['config/activeDecliConfig'].iframe.urlToRedirTo;

  if (query.length) {
    query.forEach(([key, value], index) => {
      index === 0 ? (urlRedir += `?${key}=${value}`) : (urlRedir += `&${key}=${value}`);
    });
  }
  
  store.dispatch('user/updateStateParams', {
    ...to.query,
  });

  document.location.href = urlRedir;

  // False argument will abort the current navigation, see:
  // https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  next(false);

}
