import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// import user from './old-modules/user';
// import modal from './old-modules/modal';
// import appConfig from './old-modules/appConfig';
// import webView from './modules/webView';

import global from './modules/global';
import config from './modules/config';
import user from './modules/user';
import modal from './modules/modal';
import webview from './modules/webview';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    route: (state) => state.route,
  },

  modules: {
    global,
    config,
    user,
    modal,
    webview,
  },

  strict: false,

  plugins: [

    /// createPersistedState put in try catch for avoid a problem cookie not allowed when the boilerplate into a iframe / 

    (() => {
      try {
        return createPersistedState({
          getState: (key) => {
            try {
              return JSON.parse(sessionStorage.getItem(key));
            } catch (e) {
              console.log(e);
            }
          },
          setState: (key, state) => {
            try {
              return sessionStorage.setItem(key, JSON.stringify(state));
            } catch (e) {
              console.log(e);
            }
          },
        })
      }
      catch (e) {
        console.error(e);
        return ()=>{};
      }
    })(),
  ],
});
