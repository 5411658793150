import Vue from 'vue';

/**
 * @param {String} sUid
 * @returns {Promise<number>}
 */
export default (sUid) =>
  Vue.$requestAPI.relog(sUid)
    .then(({ response, data }) => {
      if (data && data.data && data.data[0] && data.data[0].iPageId) {
        return data.data[0].iPageId;
      }

      return 1;
    })
    .catch(console.log);