export default {
  siteTitle: 'LOUIS XIII Cognac',
  prodUrl: 'experience.louisxiii-cognac.com',
  // vuepress@kwanko-config
  kwanko: {
    mclic : null, // if null Kwanko is disabled
    idFrom: 15    // Kwanko ID from
  },
  // vuepress@kwanko-config
  // vuepress@iubenda-config
  iubenda: {
    siteId: null, // ID of the banners (for all decli)
    cookiePolicyId: null, // ID of this decli banner

    // If needed copy/paste here the csConfiguration from iubenda
    // This custom conf will override the default starter config
    custom: {}
  },
  // vuepress@iubenda-config
  facebook: {
    active: false,
    fbSdkLang: 'fr_FR',
    appIds: {
      staging: 'xxx',
      prod: 'xxx'
    }
  },
  twitter: {
    active: false
  },
  iframe: {
    active: false,
    urlToRedirTo: 'https://staging-eu01-remycointreau.demandware.net/s/remycointreau-us/13keys'
  },
  optinisation: {
    active: false
  },
  idfroms: {
    untagged: 0,
    facebook: 2,
    twitter: 3,
    whatsapp: 4,
    messenger: 5,
    urlcopy: 6
  },
  linkNumbers: {
    facebook: 2,
    twitter: 2,
    urlcopy: 3,
    whatsapp: 3,
    messenger: 4
  }
}
