<template>
  <div class="pt-4">
    <header>
      <h3 class="text-primary text-xl" v-html="$t('modals.shareLink.title')"></h3>
      <p class="text-xs text-gray-dark pb-1">{{$t('modals.shareLink.subtitle')}}</p>
    </header>
    <div>
      <NCopy :mainColor="colorStyle.primary" class="w-full" data-cy="share-url" :value="websiteUrl" @copy="trackClick(urlCopyLinkNumber)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NCopy from "@team-uep/n-copy";
import design from '@/validators/design'

export default {
  name: 'ShareLinkModal',
  data () {
    return {
      colorStyle: design.colors,
      isCopied: false
    }
  },
  components : {
    NCopy,
  },
  computed: {
    ...mapGetters({
      visitId: 'global/visitId',
      decliConfig: 'config/activeDecliConfig',
      idu: 'user/idu',
    }),

    websiteUrl () {
      return `${window.location.origin}?idfrom=${this.decliConfig.idfroms.urlcopy}&idup=${this.idu}`
    },

    urlCopyLinkNumber() {
      return this.decliConfig.linkNumbers.urlcopy;
    }
  },
  methods: {

    trackClick (iLinkNumber) {
      this.$requestAPI.trackPageClick({
        iIdu: this.idu,
        iVisitId: this.visitId,
        iPageId: this.$route.meta.pageId,
        iLinkNumber
      })
        .catch(console.log);
    }
  }
}
</script>

<style lang="scss" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}  

</style>
