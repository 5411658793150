<template>
  <div v-html="content" class="terms text-black"></div>
</template>

<script>
import { data } from '@team-uep/n-modal';
import termsContent from '@/docs/terms/terms.dirt.html'

export default {
  name: "TermsModal",
  data() {
    return {
      content: termsContent,
    }
  },
  mounted() {
    this.$emit('updateSize', 'max')
  }
};
</script>

<style lang="scss" scoped>
.terms {
  ::v-deep h1, ::v-deep h2, ::v-deep h3, ::v-deep h4, ::v-deep h5, ::v-deep h6 {
    margin: 1em 0;
  }

  ::v-deep h1 {
    @apply text-xl
  }
  ::v-deep h2 {
    @apply text-lg
  }
  ::v-deep h3, ::v-deep h4, ::v-deep h5, ::v-deep h6 {
    @apply text-md
  }
  ::v-deep p, ::v-deep ul {
    @apply text-xs;
    margin: 1em 0;
  }

  ::v-deep li::before {
    content: '- '
  }

  ::v-deep a {
    @apply text-primary;

    &:hover {
      @apply underline;
    }
  }
}
</style>
