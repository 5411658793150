<template>
  <div>
    <!-- MAIN VIEW -->
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view></router-view>
    </transition>

    <!-- MODAL -->
    <Modal
      :modalConfig="modalConfig"
      @close="updateModalConfig({ isOpen: false, modalComponent: '' })"
    ></Modal>

    <SiteFooter v-if="['register', 'enigma1', 'enigma2', 'enigma3', 'enigma4', 'enigma5', 'enigma6', 'enigma7', 'enigma8', 'enigma9', 'enigma10', 'enigma11', 'enigma12', 'enigma13'].indexOf($route.name) === -1"></SiteFooter>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from '@/components/modals/Modal'

export default {
  name: 'Container',

  components: {
    Modal
  },

  computed: {
    ...mapGetters(['modalConfig']),

    ...mapGetters({
      stateParams: 'user/stateParams',
    }),
  },

  methods: {
    ...mapActions(['updateModalConfig']),
  },

  mounted () {
    // OPEN TERMS MODAL
    if (this.$route.query.modal != null && this.$route.query.modal === 'terms') {
      this.updateModalConfig({ isOpen: true, modalComponent: 'TermsModal' })
    } else if (this.$route.query.modal != null && this.$route.query.modal === 'privacy' || (this.stateParams != null && this.stateParams.modal === 'privacy')) {
      this.updateModalConfig({ isOpen: true, modalComponent: 'CharteFbModal' })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>
