export default {
  namespaced: true,

  state: {
    imageLoaded: false,
    token: null,
    expiredAt: null,
    finop: null,
    fromId: null,
    iOpStatus: null,
    idu: null,
    parrainId: null,
    ueId: null,
    uid: null,
    visitId: null,
    oTagInfo: null,
  },

  actions: {
    setToken(context, payload) {
      context.commit('SET_TOKEN_DATA', payload);
    },

    removeToken(context) {
      context.commit('REMOVE_TOKEN_DATA');
    },

    setImageLoaded(context, payload) {
      context.commit('SET_IMAGE_LOADED', payload);
    },
  },

  mutations: {
    SET_TOKEN_DATA(state, payload) {
      state.token = payload._id;
      state.expiredAt = payload.expiredAt;
      state.finop = payload.finop;
      state.fromId = payload.fromId;
      state.iOpStatus = payload.iOpStatus;
      state.idu = payload.idu;
      state.parrainId = payload.parrainId;
      state.ueId = payload.ueId;
      state.uid = payload.uid;
      state.visitId = payload.visitId || payload.iVisitId;
      state.oTagInfo = payload.oTagInfo;
    },

    REMOVE_TOKEN_DATA(state) {
      state.token = null;
      state.expiredAt = null;
      state.finop = null;
      state.fromId = null;
      state.iOpStatus = null;
      state.idu = null;
      state.parrainId = null;
      state.ueId = null;
      state.uid = null;
      state.visitId = null;
      state.oTagInfo = null;
    },

    SET_IMAGE_LOADED(state, payload) {
      state.imageLoaded = payload
    }
  },

  getters: {
    imageLoaded: state => state.imageLoaded,
    token: state => state.token,
    expiredAt: state => state.expiredAt,
    opEnded: state => state.finop,
    fromId: state => state.fromId,
    opStatus: state => state.iOpStatus,
    isRunning: state => state.iOpStatus === 1,
    isEnded: state => state.iOpStatus === 2,
    idu: state => state.idu,
    parrainId: state => state.parrainId,
    ueId: state => state.ueId,
    uid: state => state.uid,
    visitId: state => state.visitId,
    oTagInfo: state => state.oTagInfo,
    oTagInfoGaid: state => state.oTagInfo && state.oTagInfo.gaid ? state.oTagInfo.gaid : null,
    oTagInfoIdc: state => state.oTagInfo && state.oTagInfo.idc ? state.oTagInfo.idc : null,
    oTagInfoTagid: state => state.oTagInfo && state.oTagInfo.tagid ? state.oTagInfo.tagid : null,
  },
};
