<template>
  <div class="text-center text-black">
    <p>
      <a href="https://numberly.com/fr" target="_blank">
        <img class="block max-w-xs mx-auto" :src="numberlyLogo" alt="Numberly logo" />
      </a>
    </p>

    <p v-html="$t('footer.realisation.address')"></p>

    <p>
      <a :href="$t('footer.realisation.numberly')" target="_blank">www.numberly.com</a><br/>
      {{$t('footer.realisation.contact')}}<br/>
      {{$t('footer.realisation.email')}} : <a class="text-primary" data-cy="mailto-contact" :href="`mailto:${$t('footer.realisation.mailto')}?subject=${$t('footer.contact.subject')}`" target="_blank">{{$t('footer.realisation.mailto')}}</a>
    </p>
  </div>
</template>

<script>
import NumberlyInternationalLogo from '@/assets/logos/numberly-international.svg';
import NumberlyFranceLogo from '@/assets/logos/numberly-france.svg';

export default {
  name: 'RealisationModal',

  data: function() {
    return {
      numberlyInternationalLogoSrc: NumberlyInternationalLogo,
      numberlyFranceLogoSrc: NumberlyFranceLogo,
    };
  },

  computed: {
    numberlyLogo: function() {
      return this.$store.state.config.appConfig.activeDecli.name === 'fr'
        ? this.numberlyFranceLogoSrc
        : this.numberlyInternationalLogoSrc;
    },
  },
};
</script>

<!-- CSS HERE NOT WORKING ON DYNAMIC CONTENT... SEE global.scss -->
<style lang="scss" scoped>
</style>
